import * as React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { PartnerSignupForm } from './PartnerSignupForm';
import { useModal } from '../hooks/useModal';
import { ZeroStylesButton } from './ZeroStylesButton';

const style = {
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export const ModalWrapper = () => {
  const [modalState, setModalState] = useModal();
  const getModalContent = (type) => {
    switch (type) {
      case 'demo':
        return <PartnerSignupForm />;
      default:
        return <Typography>Default</Typography>;
    }
  };
  return (
    <>
      <Modal
        open={modalState.showing}
        onClose={() => {
          setModalState({ showing: false, type: null });
        }}
        aria-labelledby={`${modalState.type}-modal}`}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ZeroStylesButton
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setModalState({ showing: false, type: null });
            }}
          >
            <Close />
          </ZeroStylesButton>
          {getModalContent(modalState.type)}
        </Box>
      </Modal>
    </>
  );
};
