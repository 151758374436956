/**
 * React and friends
 */
import React from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Material UI icon components
 */
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

/**
 * Formatting utilities
 */
import { decorateValueType } from '../../utils';

/**
 * Contains title, value, and messaging for debt/savings messaging
 */
const DeltaNumber = ({ className, delta, type, isEstimate }) => {
  return (
    <div data-testid="delta-arrow-value" className={className}>
      <ArrowUpwardIcon id="up-arrow" color="secondary" />
      <ArrowDownwardIcon id="down-arrow" color="primary" />
      {isEstimate ? 'est. ' : ''}
      {decorateValueType(type, Math.abs(delta))}
    </div>
  );
};

/**
 * Styled version of base component
 */
const DeltaNumberStyled = styled(DeltaNumber)`
  font-family: 'Majorant-Medium';
  width: fit-content;
  border-radius: 6px;
  padding: 2px 10px 2px 4px;
  background: ${({ delta }) =>
    delta <= 0 ? 'rgba(36, 171, 139, 0.15)' : '#FF000077'};
  color: ${({ delta }) => (delta <= 0 ? '#24ab8b' : 'red')};
  display: ${({ delta }) => (delta == null ? 'none' : undefined)};

  & .MuiSvgIcon-root {
    width: 0.6em;
    height: 0.6em;
    margin: auto;
  }
  & #up-arrow {
    display: ${({ delta }) => (delta <= 0 ? 'none' : undefined)};
    color: red;
  }
  & #down-arrow {
    display: ${({ delta }) => (delta >= 0 ? 'none' : undefined)};
    color: #24ab8b;
  }
`;

/**
 * Default export is styled version
 */
export default DeltaNumberStyled;
