// Status of the application, if it is production
export const isProduction =
  process.env.GATSBY_PROJECT_ID === 'solve-product-prod';

// Environment suffix to use in our current API url
export const environmentSuffix = isProduction ? 'prod' : 'dev';

// URL for the Solve Finance API
export const apiUrl = `https://us-east1-solve-product-${environmentSuffix}.cloudfunctions.net/api-app`;

const apiInternalSuffix = isProduction ? '' : '-staging';

export const apiInternalUrl = process.env.USE_EMULATOR
  ? 'http://localhost:5001/solve-mdop-staging/us-central1/app'
  : `https://us-central1-solve-mdop${apiInternalSuffix}.cloudfunctions.net/app`;

export const API_VERSION = {
  V0: 'v0',
  V1: 'v1',
};
// Keyword for accessing the new employer page
export const newEmployerSubstring = 'Save$';

// Download link for GLBA pdf file */
export const downloadUrlGLBA =
  'https://drive.google.com/uc?id=1oQggjLNZcjY8EVk-P8oqCBzpNDxrqJqn&export=download';

// Factor to multiple against house value to get the loan amount
export const loanAmountFactor = 0.8;

// Factor to multiple against house value to get the down payment
export const downpaymentAmountFactor = 0.2;

export const helpLink = 'https://www.solve.finance/resources/faq/';
export const debtOptimizerUrl = 'https://app.solve.finance';

// Blocked states for mortgage tools
export const blockedStatesForMortgageTools = ['NY'];

// Monitoring defaults, high number so backend always finds a result and we don't have to check for null
export const defaultMonitoringThreshold = 99999;

// States available
export const states = [
  'AK',
  'AL',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NC',
  'ND',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'DC',
  'WV',
  'WI',
  'WY',
];
