/**
 * React and utilities
 */
import React from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';

import StyledButton from '../Form/Button';
import LabelledNumber from '../LabelledNumber';
import ResultValueStyled from '../ResultValue';
import MonthlyPaymentChart from './MonthlyChartPayment';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import PaymentsOverTimeGraph from './PaymentsOverTimeGraph';
import { trackEvent } from '../../analytics';
import { getConversionValue } from '../../../utils';
import { theme } from '../../styles/GlobalTheme';
import useWindowSize from '../../hooks/useWindowSize';

const BarGraph = React.lazy(() => import('./BarGraph'));
const LineGraph = React.lazy(() => import('./LineGraph'));

const GOAL_TO_LOAN_PRODUCT = {
  'Minimize monthly payment (for sure)': '30 year fixed rate',
  'Minimize the total interest I pay': '15 year fixed rate',
  'Repay my debt as quickly as possible (with higher monthly payments)':
    '15 year fixed rate',
  'Minimize my average monthly payment (but with risk - monthly payments can go up and down)':
    '5/1 ARM',
};

/**
 * The header navigation bar.
 */
const ResultOptions = ({
  className,
  bestRates,
  data: {
    loanAmount,
    loanProgram,
    rate,
    goal,
    purpose,
    purchasePrice,
    totalFees,
    pointFees,
    tool,
    yearsSinceMortgage,
    hasOffer,
  },
  setStep,
}) => {
  const currentOffer = {
    source: 'current mortgage',
    appleRate: 5.9750000000000005,
    propertyValue: purchasePrice,
    loanToValue: loanAmount / purchasePrice,
    creditScore: '700',
    lenderName: 'current mortgage',
    thirdPartyFees: 0,
    combinedPointsAndLenderFees: 0,
    pointsCreditsDollarValue: 0,
    siteName: 'current mortgage',
    interestRate: rate,
    monthlyPayment: null,
    points: 0,
    purchasePrice: purchasePrice,
    ltv: loanAmount / purchasePrice,
    loanToValueAmount: loanAmount / purchasePrice,
    oldMonthlyPayment: null,
    priceDifference: 0,
    kappa: 0,
    channelLenderURL: '',
  };
  const [bestOffer, worstOffer] = returnOfferByPrice(bestRates);
  const [lenderList, histogramData] = getHistogramData(
    convertLendersToHistogramData([...bestRates, currentOffer])
  );
  const { information, title } =
    tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS'
      ? getToolTexts(
          rate,
          bestRates,
          bestOffer?.priceDifference,
          worstOffer?.priceDifference,
          hasOffer
        )
      : {};

  const { oldMonthlyPayment } = bestOffer;

  const closingCosts = parseFloat(
    (
      bestOffer?.combinedPointsAndLenderFees + bestOffer?.thirdPartyFees
    ).toFixed(3)
  );

  const hasIncreasedMonthlyPayment =
    oldMonthlyPayment - bestOffer?.monthlyPayment < 0;

  const handleOpenLender = () => {
    trackEvent('Redirect to Lender', {
      loanGroup: 'MORTGAGE',
      offerId: bestOffer.id || bestOffer.channelLenderURL,
      transaction_id: bestOffer.id || bestOffer.channelLenderURL,
      lender: bestOffer.lenderName,
      loanSource: bestOffer.source,
      loanLocation:
        tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS'
          ? 'MORTGAGE_ADVISOR'
          : 'MORTGAGE_REFINANCE',
      value: getConversionValue('MORTGAGE'),
    });

    if (
      bestOffer &&
      bestOffer.channelLenderURL.includes('//fxo.co') &&
      !bestOffer.channelLenderURL.includes('fobs=')
    ) {
      bestOffer.channelLenderURL += bestOffer.channelLenderURL.includes('?')
        ? `&fobs=${getAnonUserId()}`
        : `?fobs=${getAnonUserId()}`;
    } else if (
      bestOffer &&
      bestOffer.channelLenderURL.includes('konduitworks.com') &&
      !bestOffer.channelLenderURL.includes('Lead_id=')
    ) {
      bestOffer.channelLenderURL += bestOffer.channelLenderURL.includes('?')
        ? `&Lead_id=${getAnonUserId()}`
        : `?Lead_id=${getAnonUserId()}`;
    }

    window.open(bestOffer?.channelLenderURL, '_blank', 'noopener noreferrer');
  };

  const showCurrentOffer = tool !== 'REFINANCE' && hasOffer;

  const actualTotalFees = pointFees + totalFees;

  const lenderName = toCamelCase(bestOffer.lenderName);

  const isWorse = tool !== 'SHOP_OFFERS' && rate - bestOffer?.interestRate <= 0;
  const { isMobile } = useWindowSize();
  return (
    <div className={className}>
      <h1 align="center" style={{ marginTop: '16px' }}>
        Best Offer We Found for Your Scenario:
      </h1>

      <div id="benefits-container">
        <LabelledNumber
          title={!hasOffer ? 'Monthly Payment' : 'Monthly Savings'}
          amount={{
            value: parseFloat(
              (!hasOffer
                ? bestOffer?.monthlyPayment
                : oldMonthlyPayment - bestOffer?.monthlyPayment
              ).toFixed(3)
            ),
            type: 'currency',
          }}
          highlighted={hasOffer}
        />

        <ResultValueStyled
          title="Interest Rate"
          offer={{
            amount: {
              value: parseFloat(bestOffer?.interestRate.toFixed(3)),
              delta:
                !hasOffer &&
                (tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS')
                  ? null
                  : bestOffer?.interestRate - rate,
            },
          }}
        />

        <ResultValueStyled
          title="Closing Costs (all)"
          offer={{
            amount: {
              value: closingCosts,
              type: 'currency',
              delta: !hasOffer
                ? null
                : Math.abs(
                    actualTotalFees - bestOffer?.combinedPointsAndLenderFees
                  ),
            },
            type: 'currency',
          }}
        />
      </div>

      {!isWorse && (
        <div id="cta-wrapper">
          <p>
            <span style={{ fontWeight: 'bold' }}>Next Step:</span> Get
            Pre-Approved
          </p>
          <p>No impact on score. Takes less than 3 minutes</p>
          <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
            <StyledButton
              variant="contained"
              onClick={handleOpenLender}
              style={{
                background: theme.green8,
                borderColor: theme.green8,
                flexGrow: 1,
              }}
            >
              Apply Now at {lenderName}
            </StyledButton>
            {['bankrate', 'nerdwallet'].includes(
              bestOffer?.source?.toLowerCase()
            ) && <p>via {toCamelCase(bestOffer?.source)}</p>}
          </div>
        </div>
      )}
      {isWorse && (
        <div id="cta-wrapper">
          <p>
            <span style={{ fontWeight: 'bold' }}>Next Step:</span> Sorry we
            couldn't find a better offer. Let us help you with our borrowing
            optimizer to see better opportunities!
          </p>
          <p>No impact on score. Takes less than 3 minutes</p>
          <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
            <StyledButton
              variant="contained"
              onClick={() =>
                window.open(
                  `https://app.solve.finance?utm_source=${tool}`,
                  '_blank'
                )
              }
              style={{
                background: theme.green8,
                borderColor: theme.green8,
                flexGrow: 1,
              }}
            >
              Get Started
            </StyledButton>
            {['bankrate', 'nerdwallet'].includes(
              bestOffer?.source?.toLowerCase()
            ) && <p>via {toCamelCase(bestOffer?.source)}</p>}
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          width: '70%',
          marginTop: '16px',
          padding: '0 16px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div style={{ whiteSpace: 'nowrap', color: '#444' }}>
          Need to change something?
        </div>
        <StyledButton
          style={{ color: '#444', borderColor: '#444' }}
          onClick={() => setStep('home')}
        >
          Edit My Inputs
        </StyledButton>
      </div>

      {hasOffer && title && <h3 id="scenario-title">{title}</h3>}
      {hasOffer && information && (
        <h4 id="scenario-description">{information}</h4>
      )}

      <div id="data-container" style={{ marginTop: '48px' }}>
        <div id="mortgage-details">
          <h3>Mortgage Details</h3>

          <h1>${roundNumbersWithLocale(loanAmount)}</h1>

          <span>{GOAL_TO_LOAN_PRODUCT[goal]}</span>

          <span>{purpose}</span>

          <span style={{ fontWeight: 'bold' }}>{bestOffer?.lenderName}</span>

          <subtitle>via {bestOffer?.source}</subtitle>
        </div>

        <div id="accordion-container">
          {(((tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS') &&
            bestRates.length >= 3 &&
            hasOffer) ||
            tool === 'REFINANCE') && (
            <Accordion defaultExpanded>
              <AccordionSummary
                style={{ alignItem: 'center' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title-container">
                  <h1>Monthly Payment Savings</h1>
                  <span>Difference between old and new monthly payments</span>
                </div>

                <span
                  style={{
                    color:
                      bestOffer?.monthlyPayment - oldMonthlyPayment > 0
                        ? 'red'
                        : 'green',
                  }}
                >
                  {bestOffer?.monthlyPayment - oldMonthlyPayment > 0 ? '-' : ''}
                  $
                  {roundNumbersWithLocale(
                    Math.abs(bestOffer?.monthlyPayment - oldMonthlyPayment)
                  )}
                </span>
              </AccordionSummary>

              <AccordionDetails>
                <div className="accordion-container">
                  {(tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS') &&
                    bestRates.length >= 3 &&
                    hasOffer && (
                      <div id="bar-graph">
                        <React.Suspense fallback={<></>}>
                          <BarGraph
                            histogramData={histogramData}
                            lenderList={lenderList}
                          />
                        </React.Suspense>
                      </div>
                    )}

                  {tool === 'REFINANCE' && (
                    <div id="bar-graph">
                      <MonthlyPaymentChart
                        oldMonthlyPayment={oldMonthlyPayment}
                        monthlyPayment={bestOffer?.monthlyPayment}
                        lenderName={bestOffer?.lenderName}
                      />
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion defaultExpanded>
            <AccordionSummary
              style={{ alignItem: 'center' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="accordion-title-container">
                <h1>Total Closing Cost</h1>
                <span>Lender Fees + Points + Third Party Costs</span>
              </div>

              <span>${roundNumbersWithLocale(closingCosts)}</span>
            </AccordionSummary>

            <AccordionDetails>
              <div className="accordion-container">
                <div>
                  <p>Down Payment</p>
                  <span>
                    ${roundNumbersWithLocale(purchasePrice - loanAmount)}
                  </span>
                </div>

                <div className="accordion-details-right">
                  {showCurrentOffer && <p>Current</p>} <p>Best Offer</p>
                </div>

                <div>
                  <div className="mortgage-parameters">
                    <p>Upfront Lender Fees</p>

                    <div>
                      {showCurrentOffer && (
                        <span>${roundNumbersWithLocale(actualTotalFees)}</span>
                      )}
                      <span>
                        $
                        {roundNumbersWithLocale(
                          bestOffer?.combinedPointsAndLenderFees
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mortgage-parameters">
                    <p>3rd Party Costs (est)</p>

                    <div>
                      {showCurrentOffer && <span>?</span>}
                      <span>${bestOffer?.thirdPartyFees}</span>
                    </div>
                  </div>

                  <div className="mortgage-parameters">
                    <p>Points</p>

                    <div>
                      {showCurrentOffer && (
                        <span>
                          {roundNumbersWithLocale(pointFees / loanAmount)}%
                        </span>
                      )}
                      <span>{roundNumbersWithLocale(bestOffer?.points)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              style={{ alignItem: 'center' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div className="accordion-title-container">
                <h1>Monthly Recurring Cost</h1>
              </div>

              <div>
                <span>
                  ${roundNumbersWithLocale(bestOffer?.monthlyPayment) || 0}
                </span>

                <div
                  className={
                    'accordion-increase ' +
                    (hasIncreasedMonthlyPayment ? 'red' : 'green')
                  }
                >
                  {hasOffer && (
                    <>
                      {hasIncreasedMonthlyPayment ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}{' '}
                      $
                      {roundNumbersWithLocale(
                        Math.abs(oldMonthlyPayment - bestOffer?.monthlyPayment)
                      )}
                    </>
                  )}
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              {(tool === 'COMPARE_OFFERS' || tool === 'SHOP_OFFERS') && (
                <div id="amortization-schedule">
                  <div>Amortization Schedule</div>
                  <React.Suspense fallback={<></>}>
                    <LineGraph
                      guaranteedRate={bestOffer?.interestRate}
                      loanAmount={loanAmount}
                      loanProgram={loanProgram}
                    />
                  </React.Suspense>
                </div>
              )}

              {tool === 'REFINANCE' && (
                <PaymentsOverTimeGraph
                  lenderFees={
                    bestOffer?.pointsCreditsDollarValue +
                    bestOffer?.combinedPointsAndLenderFees
                  }
                  thirdPartyFees={bestOffer?.thirdPartyFees}
                  monthlyPayment={bestOffer?.monthlyPayment}
                  oldMonthlyPayment={oldMonthlyPayment}
                  yearsLeft={yearsSinceMortgage}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div id="edit-button">
        <StyledButton onClick={() => setStep('home')}>
          Edit My Inputs
        </StyledButton>

        <StyledButton
          variant="contained"
          onClick={handleOpenLender}
          style={{ background: theme.green8, borderColor: theme.green8 }}
        >
          Apply Now at {bestOffer.lenderName}
        </StyledButton>
      </div>
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledResultOptions = styled(ResultOptions)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .accordion-increase {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    color: #24ab8b;
    font-size: 13px;
  }

  .red {
    color: red;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    padding-right: 16px;
  }

  .mortgage-parameters {
    display: flex;
    justify-content: space-between;
    margin-top: 1.2rem;

    & > div {
      display: flex;

      & > span {
        width: 100px;
        text-align: right;
      }
    }
  }

  .green {
    color: #24ab8b;
  }

  .accordion-container {
    width: 100%;
    font-size: 13px;

    .accordion-details-right {
      display: flex;
      justify-content: flex-end;

      & > p {
        width: 100px;
        text-align: right;
        margin-top: 1.2rem;
      }
    }

    & > div:first-child {
      display: flex;
      justify-content: space-between;
    }
  }

  .accordion-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 24px;

    & > h1 {
      color: #000;
      font-family: Majorant-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & > span {
      font-feature-settings: 'cv11' on, 'ss01' on;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 0.2rem;
    }
  }

  #benefits-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 36px;
    margin-bottom: 12px;
    width: 100%;
  }

  #scenario-title {
    margin-top: 42px;
    width: 50%;
    font-family: Majorant-Medium;
  }

  #scenario-description {
    width: 50%;
    font-weight: unset;
    margin-top: 1.5rem;
  }

  #amortization-schedule {
    height: 20rem;
    width: 25rem;
    min-width: 0px;
    margin-bottom: 5px;
  }

  #mortgage-details {
    display: flex;
    flex-direction: column;

    & > span:nth-child(3) {
      margin-top: 1rem;
    }

    & > subtitle {
      margin-top: 0.5rem;
    }

    & > h1 {
      color: #000;
      font-family: Majorant-Medium;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.04em;
      line-height: 46px;
    }

    & > subtitle {
      font-feature-settings: 'cv11' on, 'ss01' on;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  #mortgage-data {
    display: flex;
    gap: 7rem;
    margin-top: 3rem;
  }

  #data-container {
    display: flex;
    align-items: flex-start;
    gap: 7rem;
    margin: 0;
  }

  & > p {
    font-family: Majorant-Regular;
    color: grey;
    margin-top: 0.5rem;
    font-size: 12px;
    margin-bottom: 2rem;
  }

  #edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 32px;
    width: 70%;
  }

  #cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 70%;
    background: #eee;
    border: 1px solid #ddd;
    margin-top: 16px;
    padding: 24px;
  }

  #bar-graph {
    height: 25rem;
  }

  #accordion-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (max-width: ${maxWidthForIsMobile}) {
    #benefits-container {
      flex-direction: column;
      gap: 30px;
      margin-top: 30px;
    }

    #scenario-title,
    #scenario-description,
    #bar-graph {
      width: 100%;
    }

    #bar-graph {
      height: 20rem;
    }

    #data-container {
      flex-direction: column;
      width: inherit;
      gap: 30px;
    }

    #edit-button {
      flex-direction: column;
      width: 100%;
    }
  }
`;

StyledResultOptions.displayName = 'ResultOptions';

export default StyledResultOptions;

// TODO - Anything below here is old code from another folder that should be refactored or migrated

const getToolTexts = (rate, lenders, bestOfBest, bestOfWorst, hasOffer) => {
  let information = `We have searched through ${lenders.length} lenders to see how your current situation & offer stands. We compared all the closing costs of loans with an interest rate of ${rate}%, or if we only found a nearby interest rate from a particular lender we converted it to an offer of the same rate using points and credits for a fair comparison. Hover over a lender box to see details, the best lender is in blue.`;

  const lowResultTitle =
    bestOfBest === -1
      ? `You have the best price by $${roundNumbersWithLocale(
          Math.abs(bestOfWorst)
        )}. We are currently finding sparse results in searching for the best price for your scenario. Please check back at a later date.`
      : `The best price we found would save you $${roundNumbersWithLocale(
          Math.abs(bestOfBest)
        )} when taking into account both rate and total fees. We are currently finding sparse results in searching for the best price for your scenario. Please check back at a later date.`;

  let title = 'Cost Comparison for Same Loan';

  if (lenders.length < 3) {
    information = lowResultTitle;
    title = '';
  }

  if (!hasOffer) {
    information = '';
    title = '';
  }

  return { information, title, lowResultTitle };
};

const addToBucket = (lender, myDict, lenderPriceDict) => {
  // positive savings are in index 1, if it is null we do a slightly diff calculation for negative value buckets (floor value instead of ceiling -873 => -1000)
  if (typeof lender[1] === 'number') {
    let priceLocation = Math.ceil(lender[1] / 1000) * 1000;
    lenderPriceDict[lender[0]] = lender[1];
    if (!myDict[priceLocation]) {
      // if empty hash create an array with that lender
      myDict[priceLocation] = [lender[0]];
    } else {
      // add lender to an array of that price point (representing a x-axis location on histogram)
      myDict[priceLocation].push(lender[0]);
    }
  } else if (typeof lender[2] === 'number') {
    let priceLocation = Math.floor(lender[2] / 1000) * 1000;
    lenderPriceDict[lender[0]] = lender[2];
    if (!myDict[priceLocation]) {
      // if empty hash create an array with that lender
      myDict[priceLocation] = [lender[0]];
    } else {
      // add lender to an array of that price point (representing a x-axis location on histogram)
      myDict[priceLocation].push(lender[0]);
    }
  }
};

const convertLendersToHistogramData = (lenders) => {
  const thing = lenders.map((lender) => {
    const isPriceZero = lender.priceDifference === 0;
    const isPriceHigherThanZero = lender.priceDifference > 0;
    if (isPriceZero) {
      return [`${lender.lenderName}`, 0, null];
    }
    return [
      `${lender.lenderName}`,
      isPriceHigherThanZero ? lender.priceDifference : null,
      isPriceHigherThanZero ? null : lender.priceDifference,
    ];
  });
  return thing;
};

const isBestPrice = (lender, appleData) => {
  if (appleData[0][0] === lender) {
    return true;
  }
  return false;
};

const getHistogramData = (appleData) => {
  let histogramArray = [];
  let myDict = {};
  let lenderPriceDict = {}; // so we can look up exact price of each lender without searching array

  for (let lender of appleData) {
    addToBucket(lender, myDict, lenderPriceDict);
  }
  for (let price of Object.keys(myDict)) {
    let graphObject = {};
    graphObject['savings'] = price;
    for (let lender of myDict[price]) {
      graphObject[lender] = 1;

      if (isBestPrice(lender, appleData)) {
        graphObject[lender + 'Color'] = 'hsl(274, 70%, 50%)';
      } else {
        graphObject[lender + 'Color'] = 'hsl(274, 70%, 50%)';
      }
    }
    histogramArray.push(graphObject);
  }
  // sort from most to least savings
  histogramArray.sort((a, b) => b.savings - a.savings);

  return [Object.keys(lenderPriceDict), histogramArray];
};

export const createD3Data = (data) => {
  // return an array of objects, each object is a line, we will have 3 lines for interest component, principal component, remaining principal

  let principalData = {
    id: 'principal',
    data: [],
  };
  let interestData = {
    id: 'interest',
    data: [],
  };
  // first loop and get the principal data
  for (let [idx, element] of data.entries()) {
    if (idx === 0 || idx % 12 !== 0) continue;
    let principalObject = {
      x: idx,
      y: element[1],
    };
    let interestObject = {
      x: idx,
      y: element[2],
    };
    principalData.data.push(principalObject);
    interestData.data.push(interestObject);
  }
  return [principalData, interestData];
};

// returns both the best price difference, and if you have the best price returns how much better you have compared to the next best option
const returnOfferByPrice = (offers) => {
  const newOffers = offers.sort(
    (a, b) => b.priceDifference - a.priceDifference
  );
  return [newOffers[0], newOffers[newOffers.length - 1]];
};

const roundNumbersWithLocale = (value) => {
  value = Math.round(value);
  return value.toLocaleString();
};

const toCamelCase = (str) => {
  return str.replace(/\s(.)/g, function ($1) {
    return $1.toUpperCase();
  });
};
