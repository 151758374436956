import mixpanel from 'mixpanel-browser';
import { getAnonUserId } from '../utils';

export const getUTMParamsFromStorage = () => {
  return {
    utm_source: localStorage.getItem('utm_source'),
    utm_medium: localStorage.getItem('utm_medium'),
    utm_campaign: localStorage.getItem('utm_campaign'),
    utm_term: localStorage.getItem('utm_term'),
    utm_content: localStorage.getItem('utm_content'),
  };
};

mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

export const trackEvent = (name, params) => {
  const transaction_id = params.transaction_id  && getAnonUserId() + params.transaction_id.replace(/[^a-zA-Z0-9]/g, "");
  if (transaction_id) {
    params.transaction_id = transaction_id;
  }

  if (process.env.GATSBY_MIXPANEL_PROJECT_TOKEN) {
    mixpanel.track(name, { ...getUTMParamsFromStorage(), ...params });
  }

  if (typeof window.gtag === 'function') {
    window.gtag('event', name, {
      ...params,
      user_id: getAnonUserId()
    });
  }
};
