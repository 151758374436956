import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import Button from '@material-ui/core/Button';
import useWindowSize from '../../hooks/useWindowSize';

const PaymentsOverTimeGraph = ({
  lenderFees,
  thirdPartyFees,
  monthlyPayment,
  oldMonthlyPayment,
  yearsLeft,
}) => {
  const { isMobile } = useWindowSize();

  let yearly_aggr_data = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];

  let years_0_5 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];
  let years_6_10 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];
  let years_11_15 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];
  let years_16_20 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];
  let years_21_25 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];
  let years_25_30 = [
    [
      'Year',
      'Refinance: scheduled payments on new loan ($)',
      'No refinance: scheduled payments on old loan ($)',
    ],
  ];

  const yearsToGraphMap = {
    0: years_0_5,
    1: years_6_10,
    2: years_11_15,
    3: years_16_20,
    4: years_21_25,
    5: years_25_30,
  };

  let [chartPage, setChartPage] = useState(0);

  let total_payment = monthlyPayment * 12;
  let total_old = oldMonthlyPayment * 12;

  let new_total = total_payment;
  let new_old = total_old;
  let years_left = 30 - yearsLeft;
  for (let i = 0; i < 31; i++) {
    if (i == 0) {
      new_total = lenderFees + thirdPartyFees;
      new_old = 0;
    }
    if (i < years_left + 1 && i > 0) {
      new_old += total_old;
    }
    yearly_aggr_data.push([`Year ${i}`, new_total, new_old]);
    if (i == 0) years_0_5.push([`Year ${i}/Closing`, new_total, new_old]);
    if (i <= 5 && i >= 1) years_0_5.push([`Year ${i}`, new_total, new_old]);
    if (i <= 10 && i >= 6) years_6_10.push([`Year ${i}`, new_total, new_old]);
    if (i <= 15 && i >= 11) years_11_15.push([`Year ${i}`, new_total, new_old]);
    if (i <= 20 && i >= 16) years_16_20.push([`Year ${i}`, new_total, new_old]);
    if (i <= 25 && i >= 21) years_21_25.push([`Year ${i}`, new_total, new_old]);
    if (i <= 30 && i >= 25) years_25_30.push([`Year ${i}`, new_total, new_old]);

    new_total += total_payment;
  }

  return (
    <div>
      <Chart
        width={isMobile ? '400px' : '600px'}
        height={'300px'}
        chartType="ColumnChart"
        data={yearsToGraphMap[chartPage]}
        options={{
          title: `Compare Total Payments Over Time`,
          chartArea: { width: isMobile ? '50%' : '70%' },
          backgroundColor: 'white',
          fontName: 'Majorant-Medium',
          isStacked: false,
          colors: ['#0043cd', '#8B008B'],
          dataOpacity: 0.5,
          legend: {
            position: 'bottom',
            alignment: 'center',
          },
          hAxis: {
            minValue: 0,
            titleTextStyle: { fontSize: '16', fontName: 'Majorant-Medium' },
          },
          vAxis: {
            format: 'currency',

            vAxis: { viewWindow: { min: 0 } },
            direction: 1,
            titleTextStyle: { fontSize: '16', fontName: 'Majorant-Medium' },
          },
        }}
        rootProps={{ 'data-testid': '4' }}
      />

      <div
        style={{
          maxWidth: isMobile ? 300 : 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {[
          'Years 0-5',
          'Years 6-10',
          'Years 11-15',
          'Years 16-20',
          'Years 21-25',
        ].map(
          (year, index) =>
            chartPage !== index && (
              <Button
                style={{ fontFamily: 'Majorant-Medium', fontSize: '13px' }}
                onClick={() => {
                  setChartPage(index);
                }}
                color="primary"
              >
                {year}
              </Button>
            )
        )}
      </div>
    </div>
  );
};

export default PaymentsOverTimeGraph;
