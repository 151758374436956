import React from 'react';
import { Chart } from 'react-google-charts';

/**
 * Monthly payment graph
 */
const MonthlyPaymentChart = ({
  oldMonthlyPayment,
  monthlyPayment,
  lenderName,
}) => {
  const monthlyPaymentsData = [
    ['Lender', 'Cost', { role: 'style' }, { role: 'annotation' }],
    ['', oldMonthlyPayment, '#24AB8B', 'existing'],
    ['', monthlyPayment, '#CEEBFF', lenderName],
  ];

  return (
    <Chart
      width={'100%'}
      height={'100%'}
      chartType="ColumnChart"
      data={monthlyPaymentsData}
      options={{
        title: `Compare Monthly Costs`,
        fontName: 'Majorant-Medium',
        style: { wordWrap: 'break-word' },
        wordWrap: 'break-word',
        backgroundColor: 'white',
        chartArea: { width: '50%' },
        isStacked: false,
        legend: { position: 'none' },
        colors: ['#24AB8B', '#0043cd', '#888888'],
        dataOpacity: 0.5,
        hAxis: {
          title: ``,
          titleTextStyle: { fontSize: '14', fontName: 'Majorant-Medium' },
        },
        vAxis: {
          format: 'currency',
          minValue: -0,
          direction: 1,
          title: `Monthly Payment`,
          titleTextStyle: { fontSize: '14', fontName: 'Majorant-Medium' },
        },
      }}
      rootProps={{ 'data-testid': '3' }}
    />
  );
};

export default MonthlyPaymentChart;
