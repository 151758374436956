/**
 * React and friends
 */
import React, { FC } from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Material UI components
 */
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

export type RadioGroupValue = {
  value: string | boolean;
  label: string;
};

export type RadioGroupType = {
  value: string | boolean;
  setValue: (value: string | boolean) => void;
  values: RadioGroupValue[];
  className?: string;
  testid?: string;
};

/**
 * Reusable radio button
 */
const RadioGroupComponent: FC<RadioGroupType> = ({
  className,
  value,
  setValue,
  values = [],
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className={className}>
      <RadioGroup value={value} onChange={handleChange}>
        {values.map(({ value, label }) => (
          <FormControlLabel value={value} control={<Radio />} label={label} />
        ))}
      </RadioGroup>
    </div>
  );
};

/**
 * Styled version of the base component
 */
const StyledRadioGroupComponent = styled(RadioGroupComponent)`
  & {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  && .MuiFormControl-root {
    width: 100%;
  }
  && .MuiFormLabel-root {
    font-family: Majorant-Regular;
    color: #02101b;
  }
  && .MuiRadio-root {
    color: #02101b;
  }
  && .MuiOutlinedInput-notchedOutline {
    border-color: #02101b;
  }
  && .MuiOutlinedInput-root.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: #02101b;
      border-width: 2px;
    }
  }
`;

StyledRadioGroupComponent.displayName = 'RadioGroupComponent';

/**
 * Default export is the styled version
 */
export default StyledRadioGroupComponent;
