/**
 * Using Styled Components for CSS
 */
import styled, { css } from 'styled-components';
import { theme } from '../styles/GlobalTheme';
/**
 * MaterialIO components
 */
import Button from '@material-ui/core/Button';

/**
 * Styled Button. Material UI can't be used as key into
 * The HOC component style.
 */
// @ts-ignore
const CallToAction = styled(Button)`
  && {
    border-radius: 8px;
    font-size: 16px;
    height: 43px;
    ${({ width, height }) =>
      css`
        width: ${width};
        height: ${height};
      `}
    background-color: ${({ bgColor }) => bgColor || '#02101b'};
    border: 2px solid ${({ bgColor }) => bgColor || '#02101b'};
    padding: 10px 24px;
    text-transform: none;
    white-space: nowrap;
    color: ${({ bgColor, color }) =>
      color ? color : bgColor === theme.white ? theme.green4 : theme.white};

    &:hover {
      background-color: ${({ bgColor }) => bgColor || '#02101b'};
    }
  }
`;

export default CallToAction;
