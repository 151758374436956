/**
 * React and friends
 */
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';

/**
 * Material-UI components
 */
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const GeneralContext = createContext();

const { Provider, Consumer } = GeneralContext;

export const SnackbarConsumer = Consumer;

/**
 * Hook provider
 */
export function SnackbarProvider({ children }) {
  /**
   * State for the message that's gonna be display
   */
  const [message, setMessage] = useState('');

  /**
   * Visibility status of the alert
   */
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  /**
   * Status of the message (success / error)
   */
  const [status, setStatus] = useState(false);

  /**
   * Extra options added to the message
   */
  const [{ autoHideDuration }, setOptions] = useState({});

  /**
   * Callback to display the  message
   */
  const addMessage = useCallback(
    (
      alert,
      messageStatus = true,
      options = {
        autoHideDuration: 3000,
      }
    ) => {
      setStatus(messageStatus);
      setMessage(alert);
      setOptions(options);
      setIsAlertVisible(true);
    },
    []
  );

  /**
   * Memoized value for callbacks
   */
  const value = useMemo(() => ({ addMessage }), [addMessage]);

  /**
   * Message status
   */
  const severity = status ? 'success' : 'error';
  const fallbackMessage = status
    ? 'Nothing went wrong!'
    : 'Sorry, an error occurred, please wait a few minutes before you try again!';

  return (
    <Provider value={value}>
      {children}

      <Snackbar
        open={isAlertVisible}
        autoHideDuration={autoHideDuration}
        onClose={setIsAlertVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={severity}>{message || fallbackMessage}</Alert>
      </Snackbar>
    </Provider>
  );
}

/**
 * Context export to use hook
 */
export default () => useContext(GeneralContext) || {};
