/**
 * React and friends
 */
import React, { FC, ChangeEventHandler } from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Material UI components
 */
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

const StyledExpandMore = styled(ExpandMore)`
  && {
    color: black;
  }
`;

/**
 * Reusable select field
 */
const CustomSelect = ({
  className,
  label,
  value,
  onChange,
  options = [],
  inputProps,
  testid,
}) => {
  return (
    <div className={className}>
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          labelId={label}
          value={value}
          onChange={onChange}
          IconComponent={StyledExpandMore}
          inputProps={inputProps}
          label={label}
          variant="outlined"
          data-testid={testid}
        >
          {options.map((option) => (
            <MenuItem
              key={`custom-select-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

/**
 * Styled version of base component
 */
const StyledCustomSelect = styled(CustomSelect)`
  &,
  & > .MuiFormControl-root {
    width: 100%;
  }

  &&& .MuiInputLabel-root {
    font-family: Majorant-Regular;
    color: black !important;
    margin-left: 12px;
    & > input.MuiInputBase-input {
      color: #000 !important;
    }
  }
  && .MuiInputLabel-shrink {
    margin-top: -6px;
  }
  && .MuiSelect-outlined {
    font-family: Majorant-Regular;
    color: black;
    margin-top: 5px;
  }
  && .MuiOutlinedInput-notchedOutline {
    border-color: #a1a7ab;
  }
  && .MuiOutlinedInput-root.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: #a1a7ab;
      border-width: 2px;
    }
  }
`;

/**
 * Default export is styled version
 */
export default StyledCustomSelect;
