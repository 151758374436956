import styled from 'styled-components';
import CallToAction from '../components/CallToAction';
import { theme } from '../styles/GlobalTheme';

export const Spacer = styled('div')`
  margin-top: 48px;
  @media ${theme.mq.desktopLargeMin} {
    margin-top: 100px;
  }
`;
export const Row = styled('div')<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
`;

export const Column = styled('div')<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
`;

export const DesktopRow = styled(Column)`
  position: relative;
  @media ${theme.mq.desktopLargeMin} {
    display: flex;
    flex-direction: row;
  }
`;

export const SectionContainer = styled('div')`
  display: flex;
  max-width: 1100px;
  box-sizing: border-box;
  flex-direction: column;

  @media ${theme.mq.desktopLargeMin} {
    flex-direction: row;
  }
`;

export const GridContainer = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
`;

export const ButtonLink = styled(CallToAction)<{
  bgColor?: string;
  color?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 18px;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  color: ${({ color, bgColor, theme }) =>
    color ? color : bgColor ? '#fff' : theme.blue4};
  &:hover {
    filter: brightness(94%);
  }
`;
