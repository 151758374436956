/**
 * React and friends
 */
import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  KeyboardEvent,
} from 'react';

/**
 * Component level styling
 */
import styled, { css } from 'styled-components';

/**
 * Out string formating utility functions
 */
import { validateEmail, formatPhoneNumber } from './common';

/**
 * Material UI components
 */
import { TextField as Base, InputAdornment } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

/**
 * Used in the retyped password if parent component validates the password
 */
const inputPropsForCheckmark = {
  endAdornment: (
    <InputAdornment position="end">
      <CheckCircleIcon />
    </InputAdornment>
  ),
};

/**
 * Compile time type checking
 */
export type TextProps = {
  className?: string;
  label: string;
  value?: string;
  setValue: Dispatch<SetStateAction<string>>;
  adornment?: string;
  type?: string;
  passwordsMatch?: boolean;
  displayInputCheckmark?: boolean;
  setLabelForCodeInput?: Function;
  error?: boolean;
  setError?: Function;
  inputProps?: any;
};

/**
 * Base component
 */
const TextField: FunctionComponent<TextProps> = ({
  className,
  label,
  type,
  value,
  setValue,
  passwordsMatch,
  displayInputCheckmark,
  setLabelForCodeInput,
  error,
  setError,
  inputProps,
  disabled,
}) => {
  /**
   * Error message
   */
  const [errorText, setErrorText] = useState('');

  /**
   * Todo: Revisit password logic for cleanup, somewhat confusing implementation
   */
  useEffect(() => {
    if (type === 'Password' && passwordsMatch) setError(false);
  }, [passwordsMatch]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (type === 'phone') {
      const newPhoneNumber = formatPhoneNumber(e.target.value).slice(0, 12);
      setValue(newPhoneNumber);
      if (newPhoneNumber.length === 12) setError(false);
    }
  };

  /**
   * unclear if we are keeping this based on ticket or if we want to handle errors with material ui error
   */
  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === 'Please enter a valid email address') {
      setValue('');
    }
    // one off functionality for verification codes that have changing labels
    if (setLabelForCodeInput) setLabelForCodeInput('6 Digit Code');
  };
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    // not allowing bad values
    if (type === 'Password' && label === 'Retype Password') {
      setErrorText(
        passwordsMatch ? null : 'Passwords must exceed 7 chars and match'
      );
      setError(passwordsMatch);
    }
    if (type === 'email') {
      if (!validateEmail(e.target.value)) {
        setErrorText('please enter a valid email');
        setError(true);
      } else {
        setError(false);
        setErrorText(null);
      }
    }
    if (type === 'phone') {
      if (e.target.value.length < 12) {
        setErrorText('please enter a valid phone number');
        setError(true);
      } else {
        setError(false);
        setErrorText(null);
      }
    }
    // one off functionality for verification codes that have changing labels
    if (setLabelForCodeInput)
      setLabelForCodeInput('Enter 6 digit code you received here');
  };

  return (
    <div className={className}>
      <Base
        type={type}
        label={label}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={onChange}
        variant="outlined"
        InputProps={{
          ...inputProps,
          endAdornment:
            displayInputCheckmark && inputPropsForCheckmark.endAdornment,
        }}
        error={error}
        helperText={errorText}
        disabled={disabled}
      />
    </div>
  );
};

export const TextFieldCSS = css`
  width: 100%;

  & > div {
    width: 100%;
  }

  input {
    font-family: Majorant-Regular;
    color: #000;
    text-align: right;
  }

  && .MuiFormLabel-root {
    font-family: Majorant-Regular;
    color: #02101b;
    &.focus {
      color: #02101b;
    }
  }

  && .MuiOutlinedInput-notchedOutline {
    border-color: #a1a7ab;
  }

  && .MuiOutlinedInput-root.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: #a1a7ab;
      border-width: 2px;
    }
  }

  && .MuiInputBase-input {
    &:focus {
      opacity: 1;
    }
  }
`;

/**
 * Styled version of the base component
 */
const StyledTextField = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  ${TextFieldCSS}
`;

/**
 * Default export is styled version
 */
export default StyledTextField;
