/**
 * React friends
 */
import { useEffect, useState } from 'react';

/**
 *  Initialize state with undefined width/height so server and client renders match
 *
 * Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default () => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setIsMobile(window.innerWidth < 1080);
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    if (typeof window !== undefined) {
      // prevent error from gatsby server side rendering, globals need protection
      window.addEventListener('resize', handleResize);
    }
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, isMobile };
};
