import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import MenuIcon from '@material-ui/icons/Menu';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';

import { theme } from '../styles/GlobalTheme';
import SolveFinanceLogo from '../images/solveLogoBlackText.svg';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AccordionNav, Title } from './MobileHeaderAccordion';
import { debtOptimizerUrl, helpLink } from '../constants';
import { Link } from 'gatsby';

export const SolveLogo = () => {
  return <img src={SolveFinanceLogo} alt={'Solve Finance'} />;
};

const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  background-color: ${theme.white};
  padding-bottom: 8px;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  & > div:nth-child(1) {
    margin-top: 12px;
    margin-left: 16px;
  }
  & > div:nth-child(2) {
    margin-right: 16px;
    margin-top: 12px;
  }
  & > div {
    display: flex;
    align-items: center;

    & > a > .MuiSvgIcon-root {
      font-size: 27.43px;
      color: black;

      cursor: pointer;
      ${({ disableCloseButton }) =>
        disableCloseButton &&
        css`
          display: none;
        `}
    }
    & > a > img {
      max-height: 38px;
    }
  }
  & > .MuiSvgIcon-root {
    color: black;
    font-size: 28px;
    cursor: pointer;
  }
  @media screen and (max-width: 320px) {
    & > div:nth-child(1) {
      margin-left: 5%;
    }
    & > div:nth-child(2) {
      margin-right: 5%;
    }
  }
  @media ${theme.mq.desktopLargeMin} {
    display: none;
  }
`;

const menuOptions = [
  {
    title: 'Solutions',
    subtext: '',
    links: [
      {
        to: '/financial-advisor-for-your-users/',
        title: 'Partners',
        subtext: 'Enable easier borrowing for your users',
      },
      {
        to: '/financial-advisor-for-individuals/',
        title: 'Individuals',
        subtext: 'Sign up to use our Debt Optimizer',
      },
    ],
  },
  {
    title: 'Tools',
    subtext: '',
    links: [
      {
        to: debtOptimizerUrl + '?utm_source=www',
        title: 'Debt Optimizer',
        subtext: 'Put better borrowing on automatic with our full service app',
      },
      {
        to: '/how-to-borrow-online/',
        title: 'Borrow Online',
        subtext: 'Free: how to borrow online quickly',
      },
      {
        to: '/how-to-borrow-against-your-house/',
        title: 'Borrow From Your House',
        subtext: 'Free: how do you get equity out of your home?',
      },
      {
        to: '/independent-mortgage-advice',
        title: 'Home Finance',
        subtext: 'Free: independent mortgage calculators',
      },
      {
        to: '/how-much-house-can-i-afford-calculator/',
        title: 'Home Affordability Maximizer',
        subtext: 'Free: estimate your home-buying power',
      },
      {
        to: '/mortgage-dti-calculator',
        title: 'DTI Calculator',
        subtext: 'Free: quickly calculate your debt-to-income ratio',
      },
      {
        to: '/how-to-improve-your-credit-score',
        title: 'Improve Credit Score',
        subtext: 'Free: how to make your credit score better',
      },
    ],
  },
  {
    title: 'Company',
    subtext: '',
    links: [
      {
        to: '/about-solve-finance',
        title: 'About',
      },
      {
        to: 'https://apply.workable.com/solve-finance/?lng=en',
        title: 'Careers',
      },
    ],
  },
];

/**
 * Component for the Header of pages
 */
const MobileHeader = ({
  className,
  disableCloseButton = false,
  currentPage = '',
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <HeaderWrapper
        currentPage={currentPage}
        className={className}
        disableCloseButton={disableCloseButton}
        id="mobile-nav-wrapper"
      >
        <div>
          <a href="/">
            <SolveLogo />
          </a>
        </div>
        <div>
          {currentPage !== '/new-employer' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <a
                style={{ fontSize: 14 }}
                href={`${debtOptimizerUrl}/sign-in?utm_source=www`}
              >
                Log in
              </a>
              <Button
                variant="contained"
                color="primary"
                href={`${debtOptimizerUrl}/onboarding?utm_source=www`}
                style={{
                  borderRadius: 8,
                  fontSize: 14,
                  height: '30px',
                  width: '99px',
                  minWidth: '99px',
                  backgroundColor: '#02101B',
                  border: '2px solid #02101b',
                  padding: 0,
                  marginRight: '12px',
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                }}
              >
                Get Started
              </Button>
            </div>
          )}
          <MenuIcon
            styles={{ cursor: 'pointer' }}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          />
        </div>
      </HeaderWrapper>
      <SideNavStyled
        isOpen={isDrawerOpen}
        toggleDrawer={() => {
          setIsDrawerOpen(!isDrawerOpen);
        }}
      />
    </>
  );
};

const SingleLink = styled('a')`
  color: ${theme.white} !important;
  text-decoration: none;
  line-height: 1;
  display: flex;
  margin-bottom: 24px;
`;

const SideNav = ({ className, isOpen, toggleDrawer }) => {
  return (
    <Drawer open={isOpen} anchor={'left'} className={className}>
      <div>
        <IconButton
          onClick={() => {
            toggleDrawer();
          }}
        >
          <CloseIcon style={{ margin: 4 }} />
        </IconButton>
      </div>

      <ul>
        {menuOptions.map(({ title, links }) => (
          <AccordionNav
            title={title}
            toggleDrawer={toggleDrawer}
            links={links}
            key={title}
          />
        ))}
        <SingleLink
          href="/pricing/"
          onClick={() => {
            toggleDrawer();
          }}
        >
          <Title>Pricing</Title>
        </SingleLink>
        <li>
          <SingleLink
            href="/resources/"
            rel="noreferrer noopener"
            onClick={() => {
              toggleDrawer();
            }}
          >
            <Title>Resources</Title>
          </SingleLink>
        </li>
      </ul>
    </Drawer>
  );
};

SideNav.propTypes = {
  /**
   * Is SideNav  visible
   */
  isDrawerOpen: PropTypes.bool.isRequired,
  /**
   * Function to control state of the drawer being visible
   */
  toggleDrawer: PropTypes.func.isRequired,
};

SideNav.defaultProps = {
  isDrawerOpen: PropTypes.bool.isRequired,
};

const SideNavStyled = styled(SideNav)`
  width: 100%;
  min-width: 375px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%);
  transition: none;
  color: white;
  & * {
    transition: none;
  }
  & > .MuiDrawer-paperAnchorLeft {
    width: inherit;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%);
    color: white;
    transition: none !important;
    margin-top: 12px;
    & > div {
      text-align: right;
    }
    & > ul {
      list-style: none;
      padding: 0;
      margin: 20px 0 0 32px;
      & > li {
        margin-bottom: 0;
        & > a {
          color: white;
          font-family: Majorant-Medium;
          font-size: 2.5em;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }
  & .MuiSvgIcon-root {
    color: white;
  }
  @media screen and (max-width: 360px) {
    width: 290px;
    & > .MuiDrawer-paperAnchorLeft > ul {
      margin-left: 10px;
    }
  }
  @media ${theme.mq.desktopLargeMin} {
    display: none;
  }
`;

export default MobileHeader;
