import React from 'react';

/**
 * Global style applied to all pages
 */
import './src/styles/style.css';

/**
 * Default Layout
 */
import Layout from './src/components/Layout';

/**
 * Wrap all pages in a Redux Provider
 */
export const wrapRootElement = ({ element }) => <Layout>{element}</Layout>;
