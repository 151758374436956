/**
 * React and utilities
 */
import React, { useCallback } from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import ButtonStyled from '../Form/Button';

/**
 * Images
 */
import Group266 from '../../images/HomeFinance/Group 266.svg';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';

/** Text logic */
const getFeedbackMessages = (
  hasNoOffer,
  {
    tool,
    shouldIRefinanceAnswer,
    distanceFromThreshold = null,
    oldMonthlyPayment,
    monthlyPayment,
  }
) => {
  const primarybuttonText = `Let's see my options`;
  const errorButtonText = `Back to Calculator`;

  if (hasNoOffer) {
    return {
      headerText: `Sorry, we can't answer this question for your particular situation with high confidence right now`,
      subHeaderText: `If this doesn't make sense or you feel lost at all, drop us a line at info@solve.finance`,
      buttonText: errorButtonText,
    };
  }

  if (tool === 'REFINANCE' && shouldIRefinanceAnswer === false) {
    return {
      headerText: `Now is not the optimal time`,
      subHeaderText: `It may be best to stay with your current mortgage.`,
      buttonText: 'Review the results',
    };
  }

  if (
    tool === 'REFINANCE' &&
    distanceFromThreshold !== null &&
    distanceFromThreshold < 0.000625
  ) {
    return {
      headerText: `You might want to refinance now (but it's close).`,
      subHeaderText: `You can reduce your monthly payment from $${oldMonthlyPayment.toFixed(
        2
      )} to $${monthlyPayment.toFixed(2)}`,
      buttonText: primarybuttonText,
    };
  }

  return {
    REFINANCE: {
      headerText: 'Yes, you should refinance!',
      subHeaderText:
        'We have searched the internet to find the best mortgages to refinance with.',
      buttonText: primarybuttonText,
    },
    COMPARE_OFFERS: {
      headerText: 'See how you compare to the market.',
      subHeaderText: `We have looked through the market, and found the best prices for your scenario.`,
      buttonText: primarybuttonText,
    },
  }[tool];
};

/**
 * Screen to warn user of existing offer that was found
 */
const OfferFound = ({ className, bestRates, setStep, data }) => {
  const noOffers = !bestRates.length;

  const { tool } = data;

  const step = noOffers
    ? tool === 'COMPARE_OFFERS'
      ? 'scenario'
      : 'home'
    : 'resultOptions';

  const { buttonText, headerText, subHeaderText } = getFeedbackMessages(
    noOffers,
    data
  );

  const handleNextStep = useCallback(() => {
    setStep(step);
  }, [step]);

  return (
    <div className={className}>
      <div id="market-container">
        <h2>{headerText}</h2>

        <span>{subHeaderText}</span>
        {headerText === 'Now is not the optimal time' && (
          <p>Our <a href="https://app.solve.finance?utm_source=RefinanceTool&goal=MIN_MONTHLY"
              target="_blank"
              rel="noopener noreferrer"
            >borrowing optimizer</a> monitors rates for you and we notify users when it makes sense to refinance. You can turn it off anytime otherwise it's "always on" looking at credit markets for you. <a href="https://app.solve.finance?utm_source=RefinanceTool&goal=MIN_MONTHLY"
            target="_blank"
            rel="noopener noreferrer"
          >Try it out!</a>
          </p>
        )}

        <ButtonStyled variant="contained" onClick={handleNextStep}>
          {buttonText}
        </ButtonStyled>
      </div>

      <img
        id="house-image"
        src={Group266}
        alt="Small house with money coming out of the roof"
      />
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledOfferFound = styled(OfferFound)`
  display: flex;
  margin: 10rem 0 3rem;
  justify-content: space-between;
  align-items: center;

  #market-container {
    display: flex;
    flex-direction: column;

    & > h2 {
      font-family: Majorant-Medium;
      font-size: 64px;
      font-weight: 500;
      letter-spacing: -0.04em;
      line-height: 120%;
      margin-bottom: 1rem;
      width: 100%;
    }

    & > span {
      font-family: Majorant-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.04em;
      line-height: 120%;
      margin-bottom: 3rem;
      margin-bottom: 0;
      width: 70%;
    }

    & > button {
      width: 50%;
      margin-top: 2rem;
    }
  }

  #house-image {
    width: 34%;
  }

  @media screen and (max-width: ${maxWidthForIsMobile}) {
    flex-direction: column-reverse;
    margin: 0;

    #house-image {
      width: 100%;
    }

    #market-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      & > h2 {
        font-family: Majorant-Medium;
        font-size: 36px;
        font-weight: 500;
        letter-spacing: -0.04em;
        line-height: 120%;
        margin-bottom: 1rem;
        font-size: 42px;
      }

      & > span {
        font-family: Majorant-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.04em;
        line-height: 120%;
        margin-bottom: 3rem;
        margin-bottom: 0;
        width: 100%;
      }

      & > button {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
`;

StyledOfferFound.displayName = 'OfferFound';

export default StyledOfferFound;
