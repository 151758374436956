import { createTheme } from '@material-ui/core/styles';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export const themeBlack = createTheme({
  palette: {
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Majorant-Medium'],
  },
});

export const formatPhoneNumber = (stringPhone) => {
  if (stringPhone.includes('--')) return '';
  if (stringPhone.length > 12) return stringPhone;
  removeNonNumeric(stringPhone);

  if (stringPhone.length > 6 && stringPhone.split('-').length < 3) {
    stringPhone = stringPhone.slice(0, 7) + '-' + stringPhone.slice(7);
    if (stringPhone.includes('--')) return '';
  } else if (stringPhone.length > 3 && stringPhone.split('-').length < 2) {
    stringPhone = stringPhone.slice(0, 3) + '-' + stringPhone.slice(3);
  }
  return stringPhone;
};

export const AppleTouch = () => {
  return <link rel="apple-touch-icon" href="/Solve_Favicon.ico" />;
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export const HelmetContent = ({ title, descriptionContent }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={`${title}`} data-react-helmet="true"></meta>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,user-scalable=0,maximum-scale=1,minimum-scale=1"
      />
      <meta name="description" content={`${descriptionContent}`} />
      <meta name="robots" content="index, follow" />
      <html lang="en"></html>
      <AppleTouch />
    </Helmet>
  );
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    if (typeof window !== undefined) {
      // prevent error from gatsby server side rendering, globals need protection
      window.addEventListener('resize', handleResize);
    }

    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const removeNonNumeric = (xx) => {
  return typeof xx == 'string' ? xx.replace(/[^0-9.-]/g, '') : xx;
};

export const capitalizeEveryFirstLetter = (oldString) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  let tempStringArray = oldString.split(' ');
  for (let [index, word] of tempStringArray.entries()) {
    word = capitalizeFirstLetter(word);
    tempStringArray[index] = word;
  }
  return tempStringArray.join(' ');
};
