import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

const Accordion = styled('ul')`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
  flex-direction: column;
  & a {
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled('li')`
  & * {
    color: white;
  }
  height: auto;
  min-height: 40px;
  margin: 0 0 8px 0 !important;
`;

const NavButton = styled('button')`
  background-color: transparent;
  border: none;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
  height: 40px;
`;

export const Title = styled('span')`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

const Subtext = styled('span')`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
`;

export const AccordionNav = ({ title, links, toggleDrawer }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Container key={title}>
      <NavButton
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div>
          <Title>{title}</Title>
        </div>
        <div>{expanded ? <ExpandLess /> : <ExpandMore />}</div>
      </NavButton>
      <Accordion expanded={expanded}>
        {links.map(({ to, title, subtext }) => (
          <Link
            to={to}
            onClick={() => {
              setExpanded(false);
              toggleDrawer();
            }}
            key={to}
            style={{ height: '64px', width: '100%', marginLeft: '42px' }}
            tabIndex={0}
          >
            <Title>{title}</Title>
            <Subtext>{subtext}</Subtext>
          </Link>
        ))}
      </Accordion>
    </Container>
  );
};
