import * as React from 'react';
import { isProduction } from '../constants';

const APP_ID = 'h8f4u1m3';

export default () => {
  const [intercomLoaded, setIntercomLoaded] = React.useState(false);

  // https://developers.intercom.com/installing-intercom/docs/basic-javascript
  const loadIntercom = function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
        setIntercomLoaded(true);
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
        setIntercomLoaded(true);
      } else {
        w.addEventListener('load', l, false);
        setIntercomLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined' && isProduction) {
      setTimeout(() => {
        loadIntercom();
      }, 2000);
    }
  }, []);
  React.useEffect(() => {
    if (intercomLoaded) {
      window.Intercom('boot', {
        app_id: APP_ID,
      });
    }
  }, [intercomLoaded]);
  return null;
};
