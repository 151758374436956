/**
 * Component level styling
 */
import styled, { css } from 'styled-components';

/**
 * Material UI components
 */
import { Button as Base } from '@material-ui/core';

const ButtonStyled = styled(Base)`
  && {
    font-family: 'Majorant-Medium';
    font-size: 1em;
    width: 100%;
    border-radius: 8px;
    min-height: 43px;

    background-color: transparent;
    color: black;
    border: 2px solid #02101b;

    &:hover {
      background-color: white;
    }

    ${({ variant }) =>
      variant === 'contained' &&
      css`
        background-color: #02101b;
        color: white;

        &:hover {
          background-color: #02101b;
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        color: #7e848a !important;
        background-color: #e1e2e4 !important;
        border: 2px solid #e1e2e4 !important;

        &:hover {
          background-color: #02101b !important;
        }
      `}
  }

  & .MuiButton-label {
    text-transform: none;
  }

  & .MuiButton-contained {
    background-color: #02101b;
    color: white;
  }
`;

export default ButtonStyled;
