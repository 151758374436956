import * as React from 'react';
import { useForm } from 'react-hook-form';

import { RHFTextInput } from './inputs/text-input';
import ButtonStyled from './Form/Button';
import { validateEmail } from './common';
import { theme } from '../styles/GlobalTheme';

export const PartnerSignupForm = () => {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();

  const watchedName = watch('name');
  const watchedEmail = watch('email');
  const watchedRole = watch('role');

  const publishRequest = async ({ name, email, message, formName }, event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);
    setShowError(false);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then((response) => {
        console.log(response);
        if (response?.ok) {
          setShowSuccess(true);
        } else {
          setShowError(true);
        }
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <>
      {showSuccess ? (
        <h3 style={{ padding: '16px', lineHeight: '32px' }}>
          Thank you for your interest in Solve Finance! We will be in touch
          shortly.
        </h3>
      ) : (
        <>
          {showError && (
            <span style={{ color: theme.red4 }}>
              There was an error submitting your request. Please try again.
            </span>
          )}
          <h3 style={{ marginBottom: '12px' }}>Request a Demo</h3>
          <form
            name="partnerSignup"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit(publishRequest)}
          >
            <input
              type="hidden"
              name="form-name"
              value="partnerSignup"
              register={register}
            />
            <p>
              <RHFTextInput
                type="text"
                name="name"
                register={register}
                label="Your Name"
                options={{ required: true }}
                error={errors?.name && 'Required'}
                value={watchedName}
                autoFocus
              />
            </p>
            <p>
              <RHFTextInput
                type="email"
                name="email"
                register={register}
                label="Your Email"
                options={{ required: true, validate: validateEmail }}
                error={errors?.email && 'Required'}
                value={watchedEmail}
              />
            </p>
            <p>
              <RHFTextInput
                type="role"
                name="role"
                register={register}
                label="Your Role"
                options={{ required: true }}
                error={errors?.role && 'Required'}
                value={watchedRole}
              />
            </p>
            <p>
              <ButtonStyled type="submit" variant="contained">
                Send
              </ButtonStyled>
            </p>
          </form>
        </>
      )}
    </>
  );
};
