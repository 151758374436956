/**
 * React and friends
 */
import React from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Material UI components
 */
import LabelledNumber from './LabelledNumber';
import DeltaNumber from './DeltaNumber';

/**
 * Contains title, value, and messaging for debt/savings messaging
 */
const ResultValue = ({ className, title, offer: { amount }, isEstimate }) => (
  <div className={className}>
    <LabelledNumber title={title} amount={amount}>
      <div className="alternateWithComparison">
        <DeltaNumber {...amount} isEstimate={isEstimate} />

        {typeof amount.delta === 'number' && (
          <h4>{`${
            amount.delta <= 0 ? 'better' : 'worse'
          } than current loan`}</h4>
        )}
      </div>
    </LabelledNumber>
  </div>
);

/**
 * Styled version of base component
 */
const ResultValueStyled = styled(ResultValue)`
  font-family: 'Majorant-Medium';
  color: #000000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    text-transform: capitalize;
  }
  & .alternateWithComparison {
    background: white;
    display: flex;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    gap: 0.5rem;

    h4 {
      color: grey;
      font-weight: normal;
      font-size: 0.8em;
    }
  }
`;

/**
 * Default export is styled version
 */
export default ResultValueStyled;
