/**
 * React and friends
 */
import React, { FC } from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';
import { currencyFormat } from '../../utils';

/**
 * Add symbol to input value
 */
const decorateValueType = (type: string, value: number | undefined) => {
  if (typeof value === 'undefined') return '';

  if (type === 'currency') return currencyFormat(value);
  else {
    let percent = value.toFixed(3);
    while (
      percent.includes('.') &&
      '.0'.includes(percent[percent.length - 1])
    ) {
      percent = percent.slice(0, percent.length - 1);
    }
    return percent + '%';
  }
};

/**
 * Contains title, value, and messaging for debt/savings messaging
 */
const LabelledNumber = ({
  className,
  title,
  amount,
  testid,
  children,
  highlighted,
}) => {
  /**
   * Memoize some stats about the threshold value
   */
  return (
    <div className={className}>
      <h1>{title}</h1>
      <h2
        data-testid={testid}
        className={highlighted && amount.value > 0 ? 'highlighted' : ''}
      >
        {decorateValueType(amount.type, amount.value)}
      </h2>
      {children}
    </div>
  );
};

/**
 * Styled version of base component
 */
const LabelledStyled = styled(LabelledNumber)`
  color: #000000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 1em;
    line-height: 1em;
    font-weight: normal;
    margin: 0 0 4px 0;
  }

  & > h2 {
    font-family: 'Majorant-Medium';
    font-size: 2.5em;
    font-weight: normal;
    margin: 0;
  }

  .highlighted {
    background-color: rgba(36, 171, 139, 0.15);
    border-radius: 7px;
    color: #24ab8b;
    min-width: 125px;
  }
`;

/**
 * Default export is styled version
 */
export default LabelledStyled;
