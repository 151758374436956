import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonStyled from './Form/Button';
import StyledTextField from './TextField';

const ModalBody = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 32px;
  max-width: 100%;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.gray6};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const TextField = styled(StyledTextField)`
  margin: 20px 0;
`;

const WaitlistModal = ({ exitModalOpen, setExitModalOpen, handleSignUp }) => {
  const [email, setEmail] = useState('');

  return (
    <Modal
      open={exitModalOpen}
      onClose={() => {
        setExitModalOpen(false);
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalBody>
        <p>We aren't live in your state yet, sorry!</p>

        <p>
          We should be live in a few months after we get licenses in place. Sign
          up now to join the waitlist.
        </p>

        <TextField label="Email" value={email} setValue={setEmail} />

        <ButtonStyled
          variant="contained"
          onClick={() => handleSignUp(email)}
          disabled={!/\S+@\S+\.\S+/.test(email)}
        >
          Sign Up
        </ButtonStyled>
      </ModalBody>
    </Modal>
  );
};

export default WaitlistModal;
