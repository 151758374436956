import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import styled, { css } from 'styled-components';

export const activeStyles = css`
  transform-origin: left top 0px;
  transform: translate3d(0px, -33px, 0px);
  padding: 0px 3px;
  left: 14px;
  line-height: 1;
  background-color: white;
  font-size: 0.75em;
  text-transform: uppercase;
`;

type InputWrapperProps = {
  error: boolean;
  filled?: boolean;
  decoratorLeft?: boolean;
  decoratorRight?: boolean;
};

export const InputWrapper = styled('div')<InputWrapperProps>`
  width: 100%;
  display: block;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 24px;
  & input {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.1876em;
    box-sizing: border-box;
    height: 56px;
    border: 2px solid ${({ error }) => (error ? '#d9382c' : '#000')};
    border-radius: 4px;
    width: 100%;
    padding: ${({ decoratorLeft, decoratorRight }) =>
      decoratorLeft
        ? '18.5px 14px 18.5px 42px'
        : decoratorRight
        ? '18.5px 42px 18.5px 14px'
        : '18.5px 14px'};
    text-align: ${({ decoratorLeft }) => (decoratorLeft ? 'right' : 'left')};
  }
  & input:focus + div label {
    ${activeStyles}
  }
  & input + div label {
    ${({ filled }) => (filled ? activeStyles : '')}
  }
`;

export const InputLabelWrapper = styled('div')`
  position: absolute;
  height: 56px;
  top: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
`;

export const InputLabel = styled('label')<{
  active?: boolean;
  decoratorLeft?: boolean;
}>`
  position: absolute;
  text-overflow: ellipsis;
  white-space: no-wrap;
  text-align: left;
  pointer-events: none;
  top: 50%;
  padding: 0px;
  left: ${({ decoratorLeft }) => (decoratorLeft ? '40px' : '14px')};
  line-height: 1;
  width: auto;
  font-weight: 400;
  transition: all 0.15s linear 0s;
  transform: translate3d(0px, -50%, 0px);
  ${({ active }) => (active ? activeStyles : '')};
`;

export const ActiveInputLabel = styled('label')<{ active?: boolean }>`
  position: absolute;
  text-overflow: ellipsis;
  white-space: no-wrap;
  text-align: left;
  pointer-events: none;
  top: 50%;
  padding: 0px;
  left: 14px;
  line-height: 1;
  width: auto;
  font-weight: 400;
  transition: all 0.15s linear 0s;
  transform: translate3d(0px, -50%, 0px);
  ${activeStyles}
`;

export const ErrorMessage = styled('p')`
  position: absolute;
  color: #d9382c;
  font-size: 14px;
  bottom: 0;
  margin: 0;
`;

export const InputDecoratorLeft = styled('span')`
  color: #02101b;
  position: absolute;
  left: 20px;
  top: 18px;
  font-size: 16px;
`;

export const InputDecoratorRight = styled('span')`
  color: ${({ theme }) => theme.gray7};
  position: absolute;
  right: 20px;
  top: 18px;
  font-size: 16px;
`;

export interface ITextInput {
  autoComplete?: string;
  autoFocus?: boolean;
  testid?: string;
  name: string;
  label: string;
  register: UseFormRegister<{ [key: string]: string }>;
  options: RegisterOptions;
  error?: string;
  value: string | number | undefined;
  decoratorLeft?: string;
  decoratorRight?: string;
  placeholder?: string;
  onFocus?: () => void;
  type?: 'number' | 'text';
}

export interface ISelect {
  testid?: string;
  name: string;
  register: UseFormRegister<{ [string: string]: string }>;
  options: { id: string; text: string }[];
  registerOptions: RegisterOptions | undefined;
  value?: string;
  setValue: (arg0: string) => void;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
}
