import * as React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { NavLink } from './Header';

const Dropdown = styled('ul')`
  display: none;
  position: absolute;
  top: 55px;
  left: -100%;
  min-width: 360px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 16px 0 16px 0;
  & > span {
    padding: 0 24px 10px 24px;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 120%;
    text-transform: uppercase;
  }
`;

const Node = styled('ul')`
  position: relative;
  display: flex;
  margin-right: 24px;
  height: 100%;
  z-index: 3;
  & > span {
    align-self: center;
    cursor: pointer;
  }
  & > ${Dropdown} {
    display: none;
    visibility: hidden;
  }
  &:hover,
  &:focus {
    & > span {
      border-bottom: 3px solid #0286e7;
      margin-bottom: -3px;
    }
    & > ${Dropdown} {
      display: flex;
      visibility: visible;
    }
  }
  & > span:hover,
  & > span:focus {
    border-bottom: 3px solid #0286e7;
    margin-bottom: -3px;
    & + div {
      display: flex;
    }
  }
`;

const Title = styled('span')`
  font-size: 16px;
  line-height: 150%;
`;
const Subtext = styled('span')`
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
`;

const NavItem = styled('li')`
  box-sizing: border-box;
  &: hover {
    background-color: #e1e2e4;
  }
  & a {
    display: flex;
    flex-direction: column;
    padding: 6px 24px 16px 24px;
  }
`;

export const NavNode = ({ title, subtext, children }) => (
  <Node tabIndex={-1}>
    {title && (
      <>
        <span tabIndex={0} role="menuitem" aria-haspopup="true">
          {title}
        </span>
        <KeyboardArrowDownIcon />
      </>
    )}
    <Dropdown role="menu" className={'nav-dropdown'}>
      {subtext && <span role="none">{subtext}</span>}
      {children.map((child) => (
        <NavItem key={child.to} role="none">
          <NavLink to={child.to} role="menuitem">
            <Title>{child.title}</Title>
            {child.subtext && <Subtext>{child.subtext}</Subtext>}
          </NavLink>
        </NavItem>
      ))}
    </Dropdown>
  </Node>
);
