/**
 * React and friends.
 */
import React, { FC, ChangeEvent, useState, useRef, useEffect } from 'react';

/**
 * Component level styling.
 */
import styled from 'styled-components';

/**
 * MaterialUI components.
 */
import { TextField as Base, InputAdornment } from '@material-ui/core';

/**
 * Re-use CSS from the base TextField without wrapping
 * the component directly.
 */
import { TextFieldCSS } from '../TextField';

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const removeCommasAfterDecimal = (value) => {
  if (!value.includes('.')) return value;
  const decimalSplit = value.split('.');
  const leftOfDecimal = decimalSplit[0];
  const rightOfDecimal = decimalSplit[1].replace(/[^0-9.-]/g, '');

  return leftOfDecimal + '.' + rightOfDecimal;
};

/**
 * Reusable currency field. Mis-named, because it is intended for
 * both rates and currencies, and works for all numeric values.
 *
 * It should not be used for number-like string such as phone or zip
 * code, because numbers cannot start with zero, while identifiers can.
 *
 * Pass in an instance of a ProtectedState
 * type, along with labelling information, and changes to the inputs
 * will back-propagate good numeric values.
 *
 * The caveat is that this will always be a number, and never undefined,
 * which is the intended behavior, but it also means that when deleting
 * a number it will default to zero, which is a valid number.
 *
 * We don't do the full syn to global state in this field component,
 * because we want to be able to gate/group the values and require
 * multiple fields to be validated before moving on to the next step.
 */
const CurrencyField = ({
  adornment,
  value,
  placeholder,
  setValue,
  testid,
  onFocus: handleOnFocus,
  ...props
}) => {
  /**
   * Local state for checking and testing values without needing
   * state at a higher level. Has to allow strings to handle
   * partial numbers, e.g. decimal values in progress.
   */
  const [local, setLocal] = useState<string>('');

  /**
   * When the input value changes, strip out any string
   * characters, and flow the result. This will trigger
   * the next update step.
   *
   * The edge case we need to handle are negative and decimal
   * values. Match on floating point, but also allow trailing decimal.
   *
   * Test cases:
   * "-",
   * "-."
   * "-.0."
   * "-.0-"
   */
  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const numeric = removeCommasAfterDecimal(
      numberWithCommas(target.value.replace(/[^0-9.-]/g, ''))
    );
    const match = /^[-]?([0-9]+([.][0-9]*)?)$/.test(numeric);

    if (match || ['-', ''].includes(numeric)) {
      setLocal(numeric);
    } else if (numeric.endsWith('-')) {
      setLocal(numeric.slice(0, numeric.length - 1));
    } else if (numeric === '.') {
      setLocal('0.');
    } else if (numeric === '-.') {
      setLocal('-0.');
    } else {
      const first = new Set();

      const replace = (match: string, group: string): string => {
        if (!first.has(group)) {
          first.add(group);
          return match;
        } else {
          return '';
        }
      };
      setLocal(numeric.replace(/([-.])/g, replace));
    }
  };

  /**
   * Update the parent state when the local state updates, but
   * exclude the first time it renders, because we just got the
   * state from the target to be updated (probably). This will
   * break an infinite loop in some cases.
   */
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else if (/[-]?([0-9]*[.])?[0-9]+/.test(local)) {
      setValue(Number(local.replaceAll(',', '')));
      return;
    }

    setValue('');
  }, [local]);

  useEffect(() => {
    onChange({
      target: { value: value.toString() },
    } as ChangeEvent<HTMLInputElement>);
  }, []);

  return (
    <Base
      {...props}
      variant="outlined"
      value={local}
      onChange={onChange}
      InputProps={{
        'data-testid': testid,
        startAdornment: <InputAdornment>{adornment}</InputAdornment>,
      }}
      placeholder={placeholder}
      onFocus={() => {
        onChange({
          target: { value: '' },
        } as ChangeEvent<HTMLInputElement>);

        setValue('');
      }}
    />
  );
};

/**
 * Styled version of base component
 */
const StyledCurrencyField = styled(CurrencyField)`
  ${TextFieldCSS}
`;

/**
 * Default export is styled version
 */
StyledCurrencyField.displayName = 'CurrencyField';
export default StyledCurrencyField;
