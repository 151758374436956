import * as React from 'react';

import {
  InputWrapper,
  InputLabelWrapper,
  InputLabel,
  ErrorMessage,
  ITextInput,
  InputDecoratorLeft,
  InputDecoratorRight,
} from './inputs';

export const RHFTextInput = ({
  autoComplete,
  autoFocus,
  testid,
  name,
  label,
  register,
  options,
  error,
  value,
  decoratorLeft,
  decoratorRight,
  placeholder,
  type = 'text',
  onFocus,
}: ITextInput) => (
  <InputWrapper
    error={!!error}
    filled={typeof value === 'string' ? !!value.length : !!value}
    decoratorLeft={!!decoratorLeft}
    decoratorRight={!!decoratorRight}
  >
    <input
      type={type}
      autoFocus={autoFocus}
      {...register(name, options)}
      data-testid={testid}
      autoComplete={autoComplete}
      placeholder={placeholder}
      onFocus={onFocus}
    />
    <InputLabelWrapper>
      <InputLabel
        decoratorLeft={!!decoratorLeft}
        active={value !== null && typeof value !== 'undefined'}
      >
        {label}
      </InputLabel>
    </InputLabelWrapper>
    {decoratorLeft && <InputDecoratorLeft>{decoratorLeft}</InputDecoratorLeft>}
    {decoratorRight && (
      <InputDecoratorRight>{decoratorRight}</InputDecoratorRight>
    )}
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputWrapper>
);
