import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LinkedIn, Facebook, Twitter } from '@material-ui/icons';
import { theme } from '../styles/GlobalTheme';
import { Column, Row, Spacer } from '../page-components/components';
import useWindowSize from '../hooks/useWindowSize';
import { useLocation } from '@reach/router';

const Container = styled('div')`
  background-color: #000;
  padding: 46px 80px;
  display: flex;
  flex-direction: column;
  color: #fff;
  @media ${theme.mq.desktopLargeMin} {
    flex-direction: row;
    padding: 93px 0;
  }
  & #copyright {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Content = styled('nav')`
  display: flex;
  margin: auto;
  flex-direction: column;
  @media ${theme.mq.desktopLargeMin} {
    flex-direction: row;
    max-width: 1100px;
  }
  & ul {
    list-style: none;
    margin: 0;
    & * {
      color: #fff;
    }
    & li {
      font-family: 'Majorant-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      & a {
        display: block;
        padding: 4px 4px 4px 0;
      }
    }
    & p {
      font-size: 14px;
    }
  }
`;

const BigText = styled('span')`
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  @media ${theme.mq.desktopLargeMin} {
    font-size: 32px;
    line-height: 47px;
  }
`;

const LinksContainer = styled(Row)`
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 42px;
  flex-direction: column;
  @media ${theme.mq.desktopLargeMin} {
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0;
  }
`;

const LinkRow = styled('ul')`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media ${theme.mq.desktopLargeMin} {
    flex-direction: column;
  }
`;

const ArticleLink = styled('a')`
  color: ${theme.gray4};
  text-decoration: underline;
`;

const NYText = styled('li')`
  ${({ isVisible }) =>
    isVisible ? '' : 'height: 0px; margin: 0; line-height: 0; opacity: 0;'};
`;

const Footer = () => {
  const { isMobile } = useWindowSize();
  const { pathname } = useLocation();
  const isHomepage = pathname === '/';
  const showNYCopy =
    pathname.includes('compare-mortgage-rates') ||
    pathname.includes('how-much-house-can-i-afford-calculator') ||
    isHomepage;
  return (
    <Container id="footer">
      <Content>
        <LinksContainer>
          <ul>
            <li>
              <Link to="/about-solve-finance">About</Link>
            </li>

            <li>
              <Link to="/financial-advisor-for-your-users/">Partners</Link>
            </li>

            <li>
              <Link to="/financial-advisor-for-individuals/">Individuals</Link>
            </li>

            <li>
              <Link to="/resources">Resources</Link>
            </li>

            <li>
              <Link to="/privacy/">Privacy Policy</Link>
            </li>

            <li>
              <Link to="/tos/">Terms of Service</Link>
            </li>
          </ul>

          <LinkRow>
            <li>
              <a href="https://www.linkedin.com/company/solve-finance">
                {isMobile ? <LinkedIn fontSize="large" /> : 'LinkedIn'}
              </a>
            </li>
            <li>
              <a href="https://twitter.com/solvefinance">
                {isMobile ? <Twitter fontSize="large" /> : 'Twitter'}
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/SolveFinance/">
                {isMobile ? <Facebook fontSize="large" /> : 'Facebook'}
              </a>
            </li>
          </LinkRow>
        </LinksContainer>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            justifyContent: 'flex-start',
          }}
        >
          <ul data-testid="footerRight" id="footerRight">
            <BigText>
              Get in touch
              {isMobile ? <br /> : ' '}
              <a href="mailto:info@solve.finance">info@solve.finance</a>
            </BigText>
          </ul>
          <ul>
            <li id="copyright">
              © Copyright {new Date().getFullYear()}, Solve Finance
            </li>
          </ul>
          <ul>
            <NYText isVisible={showNYCopy} aria-hidden={!showNYCopy}>
              Loans are arranged through third party lenders. This site is not
              authorized by the New York State Department of Financial Services.
              No mortgage solicitation activity or loan applications for
              properties located in the State of New York can be facilitated
              through this site.
            </NYText>
          </ul>
        </div>
      </Content>
      {isHomepage && (
        <>
          <Spacer />
          <Column
            style={{
              fontSize: 14,
              color: theme.gray4,
              marginBottom: 16,
              lineHeight: '20px',
            }}
          >
            <span>
              * ”Amid rising prices, American families fall deeper in debt” by
              Jessica Dickler,{' '}
              <ArticleLink
                href="https://www.cnbc.com/2022/01/11/amid-rising-prices-us-households-fall-deeper-in-debt.html"
                target="_blank"
                re="noopener noreferrer"
              >
                CNBC
              </ArticleLink>
              .
            </span>
            <span>
              ** Bhutta, Neil, Andreas Fuster, and Aurel Hizmo (2020). Paying
              Too Much? Price Dispersion in the US Mortgage Market," Finance and
              Economics Discussion Series 2020-062. Washington: Board of
              Governors of the Federal Reserve System,{' '}
              <ArticleLink
                href="https://doi.org/10.17016/FEDS.2020.062"
                target="_blank"
                re="noopener noreferrer"
              >
                source
              </ArticleLink>
              .
            </span>
            <span>
              ***{' '}
              <ArticleLink
                href="https://www.forbes.com/advisor/credit-cards/average-credit-card-interest-rate/"
                target="_blank"
                re="noopener noreferrer"
              >
                https://www.forbes.com/advisor/credit-cards/average-credit-card-interest-rate/
              </ArticleLink>
            </span>
            <span>
              †{' '}
              <ArticleLink href="https://www.consumerfinance.gov/about-us/blog/americans-pay-120-billion-in-credit-card-interest-and-fees-each-year/#note2">
                https://www.consumerfinance.gov/about-us/blog/americans-pay-120-billion-in-credit-card-interest-and-fees-each-year/#note2
              </ArticleLink>
            </span>
            <span>
              ††{' '}
              <ArticleLink href="https://www.debt.org/faqs/americans-in-debt/">
                https://www.debt.org/faqs/americans-in-debt/
              </ArticleLink>
            </span>
          </Column>
        </>
      )}
    </Container>
  );
};

export default Footer;
