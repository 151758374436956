import { createGlobalStyle } from 'styled-components';

export const viewportMaxWidth = '1300px';

export const widthForIsMobile = 800;

export const maxWidthForIsMobile = `${widthForIsMobile}px`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Majorant-Medium";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Medium'), url(/fonts/Majorant-Md.otf) format("otf");
  }

  @font-face {
    font-family: "Majorant-Regular";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Regular'), url(/fonts/Majorant-Rg.otf) format("otf");
  }

  @font-face {
    font-family: "Majorant-Light";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Light'), url(/fonts/Majorant-Lt.woff) format("woff");
  }

  @font-face {
    font-family: "Majorant-Thin";
    font-display: swap;
    font-weight: 400;
    src: local('Majorant-Thin'), url(/fonts/Majorant-Th.woff) format("woff");
  }


  --font-primary: 'Majorant-Regular';
  --font-secondary: 'Majorant-Medium';


  body {
    font-family: "Majorant-Regular";
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }

`;

export default GlobalStyle;
