/**
 * React and utilities
 */
import React from 'react';

/**
 * Using Styled Components for CSS
 */
import styled from 'styled-components';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import ButtonStyled from '../Form/Button';
import StyledCustomSelect from '../Form/CustomSelect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useCallback } from 'react';
import { theme } from '../../styles/GlobalTheme';

const creditScores = [
  {
    value: '760',
    label: 'Above 760',
  },
  {
    value: '740',
    label: '740-759',
  },
  {
    value: '720',
    label: '720-739',
  },
  {
    value: '700',
    label: '700-719',
  },
  {
    value: '680',
    label: '680-699',
  },
  {
    value: '660',
    label: '<680',
  },
];

export const primaryGoals = [
  {
    value: 'Minimize monthly payment (for sure)',
    label: 'Minimize monthly payment (for sure)',
  },
  {
    value: 'Minimize the total interest I pay',
    label: 'Minimize the total interest I pay',
  },
  {
    value:
      'Repay my debt as quickly as possible (with higher monthly payments)',
    label:
      'Repay my debt as quickly as possible (with higher monthly payments)',
  },

  {
    value:
      'Minimize my average monthly payment (but with risk - monthly payments can go up and down)',
    label:
      'Minimize my average monthly payment (but with risk - monthly payments can go up and down)',
  },
];

/**
 * The header navigation bar.
 */
const AdditionalInfo = ({
  className,
  setStep,
  setData,
  getBestRates,
  data: { creditScore, goal, tool, financeAnswers },
}) => {
  const handleGetOffer = useCallback(async () => {
    setStep('loadingOffers');

    getBestRates();
  }, [tool]);

  return (
    <div className={className}>
      <h2>Tell us more</h2>

      {tool !== 'REFINANCE' && (
        <>
          <span>What is your credit score?</span>

          <StyledCustomSelect
            defaultValue={'Select your credit score'}
            value={creditScore}
            options={creditScores}
            onChange={({ target }) => setData({ creditScore: target.value })}
          />

          <span>
            What is your primary goal/What should the ideal mortgage help you
            do?
          </span>

          <StyledCustomSelect
            defaultValue={'Select a goal'}
            value={goal}
            options={primaryGoals}
            onChange={({ target }) => setData({ goal: target.value })}
          />
        </>
      )}

      <div id="action-container">
        <ButtonStyled
          id="back-button"
          onClick={() =>
            setStep(
              tool === 'REFINANCE' ? 'financialSituation' : 'home'
            )
          }
        >
          Back
        </ButtonStyled>

        <ButtonStyled
          id="call-to-action-button"
          variant="contained"
          disabled={!creditScore || !goal}
          onClick={handleGetOffer}
          style={{ background: theme.green8, borderColor: theme.green8 }}
        >
          Save & Next
        </ButtonStyled>
      </div>
    </div>
  );
};

/**
 * The Final Exported Container
 */
const StyledAdditionalInfo = styled(AdditionalInfo)`
  ${StyledCustomSelect} {
    max-width: 300px;
  }

  .MuiTypography-body1 {
    font-family: var(--font-secondary);
  }

  & > h2 {
    font-size: 2.5em;
    font-weight: 400;
    font-family: Majorant-Medium;
  }

  & > span {
    font-weight: 400;
    font-family: Majorant-Medium;
    display: block;

    margin-top: 32px;
    margin-bottom: 16px;
  }

  #checkbox-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  #action-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10vh;
    gap: 10px;

    button {
      height: 43px;
    }

    #back-button {
      width: 159px;
    }

    #call-to-action-button {
      width: 177px;
    }

    @media screen and (max-width: ${maxWidthForIsMobile}) {
      flex-direction: column;

      button,
      #call-to-action-button,
      #back-button {
        width: 100%;
      }

      ${StyledCustomSelect} {
        max-width: 100%;
      }
    }
  }
`;

StyledAdditionalInfo.displayName = 'AdditionalInfo';

export default StyledAdditionalInfo;
