/**
 * Material-ui functions and components
 */
import { createTheme } from '@material-ui/core/styles';

/**
 * Colors
 */
import common from '@material-ui/core/colors/common';

export const theme = {
  white: '#ffffff',
  gray1: '#f9f9f9',
  gray2: '#e1e2e4',
  gray3: '#b1b5b8',
  gray4: '#a1a7ab',
  gray5: '#7e848a',
  gray6: '#636b71',
  gray7: '#02101B',
  gray8: '#000000',
  blue1: '#ddedf9',
  blue2: '#bfe4ff',
  blue3: '#8fd0ff',
  blue4: '#0286E7',
  blue5: '#167dc8',
  blue6: '#0f66ae',
  blue7: '#0B426a',
  blue8: '#2F80ED',
  green8: '#27AE60',
  green4: '#007181',
  red4: '#EB5757',
  mq: {
    phoneMax: '(max-width: 480px)',
    tabletMin: '(min-width: 481px)',
    tabletMax: '(max-width: 768px)',
    tabletLargeMax: '(max-width: 899px)',
    desktopSmallMin: '(min-width: 900px)',
    desktopMin: '(min-width: 769px)',
    desktopLargeMin: '(min-width: 1080px)',
  },
};

/**
 * Global theme for components
 */
const GlobalTheme = createTheme({
  palette: {
    primary: {
      main: common['black'],
    },
    secondary: {
      main: '#02101b',
    },
  },
  typography: {
    fontFamily: 'Majorant-Regular',
    fontSize: 16,
    h6: {
      fontSize: '1.5rem',
    },
  },
});

export default GlobalTheme;
