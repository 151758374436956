import * as React from 'react';

const initialState = { showing: null, type: null };

const ModalContext = React.createContext(initialState);

export const useModal = () => React.useContext(ModalContext);

export const ModalContextProvider = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  return (
    <ModalContext.Provider value={[state, setState]}>
      {children}
    </ModalContext.Provider>
  );
};
